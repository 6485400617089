import React, { createContext, useContext, useRef } from 'react'

export const RefContext = createContext()

export const RefProvider = ({ children }) => {

    const landingPage = useRef(null)
    const aboutPage = useRef(null)
    const serverListPage = useRef(null)
  
    return <RefContext.Provider value={{ landingPage, aboutPage, serverListPage }}>{ children }</RefContext.Provider>
}

export default function useRefHook() {
    return useContext(RefContext)
}
