import React, { createContext, useContext } from 'react'
import { useMediaQuery } from '@mui/material'

export const MediaQueryContext = createContext()

export const MediaQueryProvider = ({children}) => {
    const matches = useMediaQuery('(max-width:1200px)')
    return <MediaQueryContext.Provider value={{ matches }}>{ children }</MediaQueryContext.Provider>
}

export default function useMedia() {
    return useContext(MediaQueryContext)
}
