import { LandingPage, NotFoundPage } from '../modules/modules.jsx'

export const AppRoutes = [
    {
        path: '/',
        element: <LandingPage />
    },
    {
        path: 'about',
        element: <LandingPage />
    },
    {
        path: 'servers',
        element: <LandingPage />
    },
    {
        path: '*',
        element: <NotFoundPage />
    }
]