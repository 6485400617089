import React, { Suspense } from 'react'
import { useLocation } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Container } from '@mui/system'

import Background from './components/Background/Background'
import Navbar from './components/Navbar/Navbar'
import NowLoading from './components/NowLoading/NowLoading'

import { Routes } from './routes/routes'
import { AboutPage, ServerListPage } from './modules/modules'
import { AppRoutes } from './routes/AppRoutes'

import { MediaQueryProvider, RefProvider } from './hooks'
import useConfig from './hooks/useConfig.js'

const theme  = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Rajdhani',
      color: 'whitesmoke'
    },
  }
})

const App = () => {
  const { pathname } = useLocation()
  const isPathMatched = AppRoutes.some((route) => route.path === pathname.slice(1)) || pathname === '/'

  const { isLoading, config } = useConfig()

  if (isLoading && !config) return <NowLoading />

  return (
    config && (
      <>
        <ThemeProvider theme={theme}>
          <RefProvider>
            <MediaQueryProvider>
              <Suspense fallback={<NowLoading />}>
                <Background />
                { isPathMatched && <Navbar /> }
                <Container disableGutters maxWidth={false} sx={{ height: '100vh' }}>
                  <Routes />
                  { 
                    isPathMatched &&
                      <>
                        <AboutPage />
                        <ServerListPage />
                      </>
                  }
                </Container>
              </Suspense>
            </MediaQueryProvider>
          </RefProvider>
        </ThemeProvider>
      </>
    )
  )
}

export default App
