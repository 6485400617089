import React from 'react'
import { styled } from '@mui/system'
import useConfig from '../../hooks/useConfig'
import './background.css'

const VideoBackground = styled('div')({})
const Video = styled('video')({})

const Background = () => {
  const { config } = useConfig()
  return (
      <VideoBackground className='video-background'>
          <Video className='video' preload='auto' autoPlay loop muted>
              <source src={config.links.bg_video} type="video/mp4" />
          </Video>
      </VideoBackground>
  )
}

export default Background
